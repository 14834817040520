<template>
    <svg width="153" height="36" viewBox="0 0 153 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M72.7961 27.2389L78.5777 19.1214L72.9132 11.1823H77.0701L80.6268 16.3115L84.125 11.1823H88.2819L82.6174 19.1214L88.4282 27.2389H84.2714L80.6268 21.9313L76.953 27.2389H72.7961Z"
            fill="#1E2837" />
        <path
            d="M64.7011 27.685C63.1008 27.685 61.6957 27.3332 60.4857 26.6295C59.2757 25.9257 58.3292 24.9495 57.6462 23.7006C56.9729 22.4518 56.6362 21.0146 56.6362 19.3891C56.6362 17.6348 56.968 16.1133 57.6315 14.8249C58.2951 13.5264 59.2172 12.5204 60.3979 11.8068C61.5786 11.0932 62.9447 10.7364 64.4962 10.7364C66.1355 10.7364 67.526 11.1279 68.6677 11.9109C69.8191 12.684 70.6729 13.7792 71.2291 15.1965C71.7853 16.6139 71.9951 18.284 71.8585 20.2068H68.3603V18.8985C68.3505 17.1541 68.0481 15.8804 67.4528 15.0776C66.8576 14.2748 65.9208 13.8733 64.6426 13.8733C63.1984 13.8733 62.125 14.3293 61.4225 15.2411C60.7199 16.1431 60.3686 17.4663 60.3686 19.2107C60.3686 20.8362 60.7199 22.0949 61.4225 22.987C62.125 23.879 63.1496 24.325 64.4962 24.325C65.3646 24.325 66.1111 24.1318 66.7356 23.7452C67.3699 23.3487 67.8578 22.7788 68.1993 22.0355L71.6829 23.1059C71.0779 24.553 70.1411 25.678 68.8726 26.4808C67.6138 27.2836 66.2233 27.685 64.7011 27.685ZM59.2562 20.2068V17.501H70.1314V20.2068H59.2562Z"
            fill="#1E2837" />
        <path
            d="M41.6139 27.2389V26.7483L49.9861 14.3193H42.4628V11.1823H55.4749V11.6878L47.132 24.102H55.1237V27.2389H41.6139Z"
            fill="#1E2837" />
        <path
            d="M139.859 27.6849C138.317 27.6849 137.024 27.3132 135.98 26.5698C134.936 25.8264 134.151 24.8155 133.624 23.5369C133.097 22.2484 132.833 20.8063 132.833 19.2105C132.833 17.5949 133.097 16.1479 133.624 14.8693C134.151 13.5907 134.922 12.5847 135.936 11.8512C136.961 11.1079 138.225 10.7362 139.727 10.7362C141.22 10.7362 142.513 11.1079 143.606 11.8512C144.709 12.5847 145.563 13.5907 146.168 14.8693C146.772 16.138 147.075 17.585 147.075 19.2105C147.075 20.8162 146.777 22.2583 146.182 23.5369C145.587 24.8155 144.748 25.8264 143.665 26.5698C142.581 27.3132 141.313 27.6849 139.859 27.6849ZM132.233 27.2388V5.82999H135.79V15.9695H135.351V27.2388H132.233ZM139.317 24.4735C140.235 24.4735 140.991 24.2406 141.586 23.7748C142.181 23.3089 142.621 22.6795 142.904 21.8866C143.196 21.0838 143.343 20.1918 143.343 19.2105C143.343 18.2392 143.196 17.3571 142.904 16.5641C142.611 15.7613 142.157 15.127 141.542 14.6611C140.928 14.1854 140.147 13.9475 139.2 13.9475C138.303 13.9475 137.571 14.1705 137.005 14.6165C136.439 15.0526 136.019 15.6672 135.746 16.4601C135.483 17.2431 135.351 18.1599 135.351 19.2105C135.351 20.2512 135.483 21.168 135.746 21.961C136.019 22.7539 136.444 23.3734 137.02 23.8194C137.605 24.2555 138.371 24.4735 139.317 24.4735Z"
            fill="#1E2837" />
        <path
            d="M119.866 27.6998C118.695 27.6998 117.729 27.5016 116.968 27.1051C116.207 26.7087 115.602 26.2032 115.153 25.5887C114.704 24.9742 114.372 24.32 114.158 23.6262C113.943 22.9324 113.801 22.2782 113.733 21.6637C113.675 21.0393 113.645 20.5338 113.645 20.1473V11.1823H117.217V18.9133C117.217 19.4089 117.251 19.9689 117.319 20.5933C117.387 21.2078 117.544 21.8025 117.787 22.3774C118.041 22.9423 118.412 23.4082 118.9 23.7749C119.398 24.1416 120.066 24.325 120.905 24.325C121.354 24.325 121.798 24.2506 122.237 24.102C122.676 23.9533 123.071 23.7005 123.423 23.3437C123.784 22.977 124.072 22.4765 124.286 21.8421C124.501 21.2078 124.608 20.405 124.608 19.4336L126.701 20.3405C126.701 21.7083 126.438 22.9473 125.911 24.0574C125.394 25.1674 124.628 26.0545 123.613 26.7186C122.598 27.3728 121.349 27.6998 119.866 27.6998ZM125.033 27.2389V22.2584H124.608V11.1823H128.15V27.2389H125.033Z"
            fill="#1E2837" />
        <path
            d="M106.547 27.2388V19.5079C106.547 19.0024 106.513 18.4424 106.445 17.8279C106.376 17.2133 106.215 16.6236 105.962 16.0587C105.718 15.4838 105.347 15.013 104.849 14.6463C104.361 14.2795 103.698 14.0962 102.859 14.0962C102.41 14.0962 101.966 14.1705 101.527 14.3192C101.088 14.4679 100.687 14.7256 100.326 15.0923C99.9751 15.4491 99.6922 15.9447 99.4775 16.579C99.2628 17.2034 99.1555 18.0063 99.1555 18.9875L97.0624 18.0806C97.0624 16.7128 97.321 15.4739 97.8382 14.3638C98.3651 13.2537 99.136 12.3716 100.151 11.7174C101.166 11.0533 102.415 10.7213 103.898 10.7213C105.069 10.7213 106.035 10.9195 106.796 11.316C107.557 11.7125 108.162 12.218 108.611 12.8325C109.06 13.447 109.391 14.1011 109.606 14.7949C109.821 15.4887 109.957 16.1479 110.016 16.7723C110.084 17.3868 110.118 17.8873 110.118 18.2739V27.2388H106.547ZM95.5841 27.2388V5.82999H98.731V17.0994H99.1555V27.2388H95.5841Z"
            fill="#1E2837" />
        <path
            d="M150.177 24.6903C150.177 24.5143 150.318 24.3717 150.491 24.3717H152.686C152.86 24.3717 153 24.5143 153 24.6903V26.9204C153 27.0963 152.86 27.239 152.686 27.239H150.491C150.318 27.239 150.177 27.0963 150.177 26.9204V24.6903Z"
            fill="#2463EA" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M3.82301 3.82301V32.177H32.177V3.82301H3.82301ZM1.9115 0C0.85581 0 0 0.855811 0 1.91151V34.0885C0 35.1442 0.855811 36 1.91151 36H34.0885C35.1442 36 36 35.1442 36 34.0885V1.9115C36 0.85581 35.1442 0 34.0885 0H1.9115Z"
            fill="#1E2837" />
        <path class="logo-before"
            d="M18 1.9416C18 0.869284 18.8558 0 19.9115 0H34.0885C35.1442 0 36 0.869285 36 1.9416V16.3418C36 17.4141 35.1442 18.2834 34.0885 18.2834H19.9115C18.8558 18.2834 18 17.4141 18 16.3418V1.9416Z"
            fill="#2463EA" />
    </svg>
</template>